import { IUserPodModel } from "../model/compute/IPods";
import UserModel from "../model/auth/user/UserModel";
import LoginUserModel from "../model/user/LoginUserModel";
import ComputeAPIService from "../network/api/ComputeAPIService";
import APIService from "../network/APIService";
import Config from "../resources/Config";
import DomainConfig from "../resources/DomainConfig";

import Tracker from "./Tracker";
import DSUtil from "../utils/DSUtil";
// import computeParser from "../utils/ComputeParser";

export enum ErrorCode {
  InvalidParams = 400,
  Unauthorized = 401,
  InvalidVerifyCode = 403,
  NotFound = 404,
  AlreadyExist = 409,
  Expired = 410,
  AlreadyVerify = 423,
  UnprocessableEntity = 422,
  TooManyRequest = 429,
  NotAcceptable = 406,
  ServerError = 500,
  LoginError = 600,
  NotFoundUserData = 601,
}

export interface CustomError {
  message: string;
  code: ErrorCode;
}

export default class UserService {
  cachedLoginuser: LoginUserModel | null = null;
  static async login(username: string, password: string) {
    try {
      const query = `SignInUser("${username}", "${password}", platform="${DSUtil.platform()}", app_id="DAE")`;
      const api = ComputeAPIService.auth(Config.App.appId, query);
      const compute = await api;
      if (compute && compute.dataPod) {
        if (compute.dataPod.hasNone) {
          throw {
            code: ErrorCode.LoginError,
            message: "message_login_error",
          } as CustomError;
        } else {
          const token = (compute.dataPod as any).value;
          localStorage.setItem("token", token);
          return token;
        }
      } else {
        throw {
          code: ErrorCode.LoginError,
          message: "message_login_error",
        } as CustomError;
      }
    } catch (e) {
      DomainConfig.appId = null;
      DomainConfig.appInfo = null;
      localStorage.clear();
      throw e as CustomError;
    }
  }

  static async signOut(username: String) {
    try {
      APIService.cancelAllRequests();
      const query = `SignOutUser("${username}")`;
      const api = ComputeAPIService.auth(DomainConfig.appId, query);
      const compute = await api;
      if (compute && compute.dataPod) {
        localStorage.clear();
        DomainConfig.appId = null;
        DomainConfig.appInfo = null;
        Tracker.updateChannelTalkUser();
      } else {
        throw {
          code: ErrorCode.LoginError,
          message: "message_login_error",
        } as CustomError;
      }
    } catch (e) {
      throw e;
    }
  }

  // subs.status === SubscriptionStatus.NORMAL ||
  // subs.status === SubscriptionStatus.SCHEDULED_TO_END ||
  // subs.status === SubscriptionStatus.OVERDUE,

  static checkUserSubscription(userModel: LoginUserModel): boolean {
    let checkGroupSubscription: boolean = false;
    let checkUserSubscription: boolean = false;

    userModel.user.organizations.forEach((org) => {
      org.subscriptionsInUse.forEach((subscription) => {
        subscription.items.forEach((item) => {
          if (
            item.plan.enabled &&
            (item.plan.productId === "v2.permit.nice.non_external_audit" || item.plan.productId === "v2.access.premium")
          ) {
            checkGroupSubscription = true;
          }
        });
      });
    });

    userModel.user.subscriptionsInUse.forEach((subscription) => {
      subscription.items.forEach((item) => {
        if (
          item.plan.enabled &&
          (item.plan.productId === "v2.permit.nice.non_external_audit" || item.plan.productId === "v2.access.premium")
        ) {
          checkUserSubscription = true;
        }
      });
    });

    console.log(userModel.user);
    console.log(checkGroupSubscription, checkUserSubscription);

    return checkGroupSubscription || checkUserSubscription;
  }

  static async getCurrentUser() {
    try {
      const query = `GetCurrentUser()`;
      const api = ComputeAPIService.auth(DomainConfig.appId, query);
      var compute = await api;
      const authPod = compute.authPod;
      const token = localStorage.getItem("token");
      if (authPod && !authPod.hasNone && token) {
        const user = (authPod as IUserPodModel).user;

        if (user) {
          const userModel = new LoginUserModel(user, DomainConfig.appId);
          const isValidUser = UserService.checkUserSubscription(userModel);

          if (isValidUser) {
            return userModel;
          } else {
            throw {
              code: ErrorCode.Unauthorized,
              message: "message_unauthorized_error",
            } as CustomError;
          }
        } else {
          throw {
            code: ErrorCode.NotFound,
            message: "message_login_error",
          } as CustomError;
        }
      } else {
        throw {
          code: ErrorCode.NotFoundUserData,
          message: "message_login_error",
        } as CustomError;
      }
    } catch (e) {
      localStorage.clear();
      DomainConfig.appId = null;
      DomainConfig.appInfo = null;
      Tracker.updateChannelTalkUser();
      throw e as CustomError;
    }
  }

  static async getUseApplicationList(username: string) {
    try {
      const query = `ListApplicationNamesByUsername("${username}")`;

      const api = ComputeAPIService.compute(query);
      var compute = await api;
      if (compute && compute.dataPod) {
        const data = compute.dataPod.data;
        return data;
      } else {
        throw {
          code: ErrorCode.NotFoundUserData,
          message: "message_login_error",
        } as CustomError;
      }
    } catch (e) {
      throw e;
    }
  }
}
