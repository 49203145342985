import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
const drawerWidth = 240;
const useSidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(5),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: "#fff",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbarContainer: {
      marginLeft: 10,
      marginRight: 10,
    },
    menuButton: {
      marginRight: 10,
      marginLeft: 0,
      width: 20,
      height: 20,
      padding: 0,
      borderRadius: 0,
      color: "#777E8C",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(5),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(5),
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
      marginLeft: 10,
      color: "#777E8C",
    },
    icon: {
      width: 20,
      height: 20,
      marginLeft: 7.5,
    },
    listItem: {
      margin: 2,
      marginTop: 10,
      marginBottom: 10,
      height: theme.spacing(5) - 4,
      width: theme.spacing(5) - 4,
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: "#F0F3FA",
        borderRadius: 10,
        color: "#1890FF",
      },
      "&.MuiListItem-root.Mui-selected svg > path": {
        fill: "#0050B3",
      },
    },
    listItemOpen: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerWidth - 4,
    },
    headerLogo: {
      height: 30,
      marginLeft: 20,
    },
    margin: {
      margin: theme.spacing(1),
    },
    contentHeightHome: {
      height: "calc(100vh - 150px)",
    },
    backdrop: {
      marginTop: 64,
      zIndex: theme.zIndex.drawer + 1,
      background: "#fff",
    },
  })
);

export default useSidebarStyles;
