import { useEffect, useState } from "react";
import { IApplicationPodModel } from "../model/compute/IPods";
import ApplicationInfoModel, { ApplicationInfo } from "../model/user/ApplicationInfoModel";
import ComputeAPIService from "../network/api/ComputeAPIService";
import Config from "../resources/Config";

export interface ExcelAddIn {
  type: string;
  name: string;
  description: string;
  link: string;
  author: string;
  date: string;
}

export interface RestrictedAccess {
  sidebar?: string[];
  template?: string[];
}

export interface AppInfo {
  uid: string;
  name: string;
  displayName: string;
  appId: string;
  gaCode: string;
  gtmId: string;
  hosts: string[];
  chTalkPluginKey: string;
  blogUrl: string;
  favico: string;
  appIcon: string;
  helpUrl: string;
  noticeUrl: string;
  serviceUrl: string;
  homepage: string;
  logoUrl: string;
  title_en: string;
  title_ko: string;
  storeName: string;
  excelAddInList?: ExcelAddIn[];
  restricted?: RestrictedAccess;
  manage?: "true" | "false";
}

class ApplicationConfig {
  private appInfo: ApplicationInfo | null = null;

  public getAppConfig(): Promise<ApplicationInfo> {
    return new Promise((resolve) => {
      if (this.appInfo) {
        resolve(this.appInfo);
      } else {
        ComputeAPIService.compute(`GetApplication(${Config.App.uid})`)
          .then((compute) => {
            if (compute.authPod) {
              this.appInfo = new ApplicationInfoModel((compute.authPod as IApplicationPodModel).app);
              console.debug("APP : ", this.appInfo);
              resolve(this.appInfo);
            } else {
              console.warn("NOT EXIST APPLICATION !! ");
              resolve(new ApplicationInfo());
            }
          })
          .catch((error) => {
            console.warn("App Config !! ", error.errorMessage);
            resolve(new ApplicationInfo());
          });
      }
    });
  }
}

export const AppConfig = new ApplicationConfig();

export function getAppConfig(): Promise<ApplicationInfo> {
  return new Promise((resolve) => {
    AppConfig.getAppConfig().then((app) => resolve(app));
  });
}

export const useAppInfo = (dependencies: any[]) => {
  const [appInfo, setAppInfo] = useState<ApplicationInfo>();

  useEffect(() => {
    getAppConfig().then((app) => setAppInfo(app));
    // eslint-disable-next-line
  }, [...dependencies]);

  return appInfo;
};
