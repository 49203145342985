import * as React from "react";
import theme from "../config/theme";
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import GlobalStyle from "./GlobalStyles";
import SidebarMenu from "./SidebarMenu";
// import useUser from "../hook/useUser";
// import { AppConfig } from "../../app-config";
// import Tracker from "../../services/Tracker";
// eslint-disable-next-line no-unused-vars
/* global console, Excel  */

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <SidebarMenu />
      </Router>
    </ThemeProvider>
  );
};

export default App;
