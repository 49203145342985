import { IApplicationPodModel } from "../../model/compute/IPods";
import Config from "../../resources/Config";
import ComputeAPIService from "../../network/api/ComputeAPIService";
import { ApiPromise } from "../../network/APIService";

import ApplicationModel from "../auth/application/ApplicationModel";
import { AppInfo, ExcelAddIn } from "../../app-config";

const KeySet = {
  AppIcon: "appIcon",
  FavIcon: "favIcon",
  GaCode: "gaCode",
  GtmId: "gtmId",
  ChannelTalkId: "chTalkKey",
  DomainList: "domains",
  Logo: "logo",
  ServiceUrl: "service",
  HomepageUrl: "homepage",
  Blog: "blog",
  Help: "help",
  Notice: "notice",
  Title: "title",
  StoreName: "store",
  Manage: "manage",
  ExceAddIn: "excelAddIn",
  Keywords: "keywords",
};

export class ApplicationInfo {
  private defaultApp: AppInfo;

  constructor(uid?: string) {
    this.defaultApp = Config.App;
    if (uid) {
      const app = Object.values(Config.TYPE).find((e) => e.uid === uid);
      if (app) {
        this.defaultApp = app;
      }
    }
  }

  getDisplayName(): string {
    return this.defaultApp.displayName;
  }

  getAppIconUrl(): string {
    return this.defaultApp.appIcon;
  }

  getFaviconUrl(): string {
    return this.defaultApp.favico;
  }

  getGaCode(): string {
    return this.defaultApp.gaCode;
  }

  getGtmId(): string {
    return this.defaultApp.gtmId;
  }

  getChannelTalkKey(): string {
    return this.defaultApp.chTalkPluginKey;
  }

  getDomainList(): string[] {
    return [];
  }

  getLogoUrl(): string {
    return this.defaultApp.logoUrl;
  }

  /**
   * Application Services
   */
  getServiceUrl(): string {
    return this.defaultApp.serviceUrl;
  }

  getHomePage(): string {
    return this.defaultApp.homepage;
  }

  getHelpUrl(): string {
    return this.defaultApp.helpUrl;
  }

  getNoticeUrl(): string {
    return this.defaultApp.noticeUrl;
  }

  getBlogUrl(): string {
    return this.defaultApp.blogUrl;
  }

  getTitleKo(): string {
    return this.defaultApp.title_ko;
  }

  getTitleEn(): string {
    return this.defaultApp.title_en;
  }

  getTitle(lang: string) {
    return this.defaultApp[`title_${lang}`] || this.defaultApp.title_ko;
  }

  getStoreName() {
    return this.defaultApp.storeName;
  }

  canManageUsers() {
    return this.defaultApp.manage === "true";
  }

  getExcelAddInList() {
    return this.defaultApp.excelAddInList;
  }

  //   getKeywords() {
  //     return this.defaultApp.keywords;
  //   }
}

export default class ApplicationInfoModel extends ApplicationInfo {
  app: ApplicationModel;
  constructor(app: ApplicationModel) {
    super(app.uid);
    this.app = app;
  }

  get uid() {
    return this.app.uid;
  }

  get name() {
    return this.app.name;
  }

  get enabled() {
    return this.app.enabled;
  }

  get updatedDate() {
    return this.app.updatedDate;
  }

  get createdDate() {
    return this.app.createdDate;
  }

  get deletedDate() {
    return this.app.deletedDate;
  }

  getDisplayName(): string {
    return this.app.displayName;
  }

  setDisplayName(name: string): ApiPromise<ApplicationInfoModel> {
    const api = ComputeAPIService.compute(`SetApplicationDisplayName(${this.app.uid}, "${name}")`);
    return new ApiPromise((resolve, reject) => {
      api
        .then((compute) => {
          const pod = compute.authPod as IApplicationPodModel;
          resolve(new ApplicationInfoModel(pod.app));
        })
        .catch((compute) => {
          reject(compute);
        });
    }, api.cancelSource);
  }

  getAppIconUrl(): string {
    return this.app.data[KeySet.AppIcon] || super.getAppIconUrl();
  }

  setAppIconUrl(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.AppIcon, url);
  }

  getFaviconUrl(): string {
    return this.app.data[KeySet.FavIcon] || super.getFaviconUrl();
  }

  setFaviconUrl(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.FavIcon, url);
  }

  getGaCode(): string {
    return this.app.data[KeySet.GaCode] || super.getGaCode();
  }

  setGaCode(code: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.GaCode, code);
  }

  getGtmId(): string {
    return this.app.data[KeySet.GtmId] || super.getGtmId();
  }

  setGtmId(id: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.GtmId, id);
  }

  getChannelTalkKey(): string {
    return this.app.data[KeySet.ChannelTalkId] || super.getChannelTalkKey();
  }

  setChannelTalkKey(id: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.ChannelTalkId, id);
  }

  getDomainList(): string[] {
    const data = this.app.data[KeySet.DomainList];
    return data ? data.split(",") : super.getDomainList();
  }

  setDominList(list: string[]): ApiPromise<ApplicationInfoModel> {
    const data = `${list.map((e) => `${e}`).join(",")}`;
    return this.setData(KeySet.DomainList, data);
  }

  getLogoUrl(): string {
    return this.app.data[KeySet.Logo] || super.getLogoUrl();
  }

  setLogoUrl(id: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.Logo, id);
  }

  /**
   * Application Services
   */
  getServiceUrl(): string {
    return this.app.data[KeySet.ServiceUrl] || super.getServiceUrl();
  }

  setServiceUrl(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.ServiceUrl, url);
  }

  getHomePage(): string {
    return this.app.data[KeySet.HomepageUrl] || super.getHomePage();
  }

  setHomePage(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.HomepageUrl, url);
  }

  getHelpUrl(): string {
    return this.app.data[KeySet.Help] || super.getHelpUrl();
  }

  setHelpUrl(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.Help, url);
  }

  getNoticeUrl(): string {
    return this.app.data[KeySet.Notice] || super.getNoticeUrl();
  }

  setNoticeUrl(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.Notice, url);
  }

  getBlogUrl(): string {
    return this.app.data[KeySet.Blog] || super.getBlogUrl();
  }

  setBlogUrl(url: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.Blog, url);
  }

  getTitleKo() {
    return this.app.data[`${KeySet.Title}_ko`] || super.getTitleKo();
  }

  getTitleEn() {
    return this.app.data[`${KeySet.Title}_en`] || super.getTitleEn();
  }

  getTitle(lang: string) {
    let data = this.app.data[`${KeySet.Title}_${lang}`];
    return data || super.getTitle(lang);
  }

  setTitle(lang: string, title: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(`${KeySet.Title}_${lang}`, title);
  }

  getStoreName() {
    return this.app.data[KeySet.StoreName] || super.getStoreName();
  }

  setStoreName(name: string): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.StoreName, name);
  }

  setManage(b: boolean): ApiPromise<ApplicationInfoModel> {
    return this.setData(KeySet.Manage, b ? "true" : "false");
  }

  canManageUsers() {
    const manage = this.app.data[KeySet.Manage] || super.canManageUsers();
    return manage === "true";
  }

  setExcelAddInList(list: ExcelAddIn[]): ApiPromise<ApplicationInfoModel> {
    const obj = JSON.stringify(list);
    const data = btoa(encodeURIComponent(obj));
    return this.setData(KeySet.ExceAddIn, data);
  }

  getExcelAddInList() {
    const listObj = this.app.data[KeySet.ExceAddIn];
    if (listObj) {
      const data = decodeURIComponent(atob(listObj));
      const list: ExcelAddIn[] = JSON.parse(data);
      if (list && list.length > 0) {
        return list;
      }
    }
    return super.getExcelAddInList();
  }

  setKeywords(list: string[]): ApiPromise<ApplicationInfoModel> {
    const data = `${list.map((e) => `${e}`).join(";")}`;
    return this.setData(KeySet.Keywords, data);
  }

  //   getKeywords(): string[] {
  //     const data = this.app.data[KeySet.Keywords];
  //     return data ? data.split(";") : super.getKeywords();
  //   }

  private setData(key: string, data: string): ApiPromise<ApplicationInfoModel> {
    const api = ComputeAPIService.compute(`SetApplicationData(${this.app.uid}, "${key}", "${data}")`);
    return new ApiPromise((resolve, reject) => {
      api
        .then((compute) => {
          if (compute.dataPod && compute.dataPod.content.data) {
            this.app.data[key] = data;
            resolve(new ApplicationInfoModel(this.app));
          } else {
            reject("DOES NOT CHANGE");
          }
        })
        .catch((compute) => {
          reject(compute);
        });
    }, api.cancelSource);
  }
}
