import { Snackbar, SnackbarCloseReason } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";
import * as React from "react";
import styled from "styled-components";

const StyledSnakebar = styled(Snackbar)`
  top: 60px;
`;

interface IProp {
  open: boolean;
  type: Color;
  message: string;
  onClose?: (event?: React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => void;
}

const CustomSnakebar = (props: IProp) => {
  const { open, type, message, onClose } = props;
  return (
    <StyledSnakebar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </StyledSnakebar>
  );
};

export default CustomSnakebar;
