const moment = require("moment").default || require("moment");
import { Subject } from "rxjs";
import Config from "../../resources/Config";
import { ProductPlan, TierName } from "../auth/billing/item/ProductPlanModel";
import SubscriptionModel from "../auth/billing/SubscriptionModel";
import UserModel from "../auth/user/UserModel";

const KEY_USER_SEARCH_HISTORY = "data:search-history";
const KEY_USER_ISSUE_COMPANY_SEQUENCE = "data:issue-company-sequence";
const KEY_USER_TABS = "data:user-tabs";
const KEY_USER_FAVORITE_MENUS = "data:user-favorite-menus";

/**
 * Local Storage
 */
const KEY_NEED_SHOW_PAYMENT_METHOD = "need_show_payment";

type UserData = { [key: string]: any };

export default class LoginUserModel {
  user: UserModel;
  private appId: string;
  private userData: UserData;

  private favoritesSubject: Subject<string[]> = new Subject();

  constructor(user: UserModel, appId: string) {
    this.user = user;
    this.appId = appId;
    this.userData = {};
  }

  appName(): string {
    return this.appId;
  }
  uid(): string {
    return this.user.uid;
  }

  /**
   * @returns User의 Email을 가져온다
   */
  email(): string {
    return this.user.email;
  }

  userName(): string {
    return this.user.username;
  }

  name(): string {
    return this.user.name;
  }

  organizationName(): string {
    return this.user.organizationName;
  }

  teamName(): string {
    return this.user.teamName;
  }

  position(): string {
    return this.user.position;
  }

  userImage(): string | null {
    return null;
  }

  /**
   * @returns User의 검색 히스토리를 가져 온다.
   */
  searchHistory() {
    return this.userData[KEY_USER_SEARCH_HISTORY];
  }

  /**
   * Issue Company 순서
   */
  issueCompanySequence() {
    return this.userData[KEY_USER_ISSUE_COMPANY_SEQUENCE];
  }

  /**
   * User Tabs
   */
  userTabs() {
    return this.userData[KEY_USER_TABS];
  }

  favoriteMenus() {
    return this.userData[KEY_USER_FAVORITE_MENUS];
  }

  favoriteMenuObservable() {
    return this.favoritesSubject;
  }

  needChangePassword() {
    return this.user.lastPasswordChangedDate === null;
  }

  lastPasswordChangedDate() {
    let date = "";
    if (this.user.lastPasswordChangedDate) {
      date = this.user.lastPasswordChangedDate;
    }
    return date;
  }

  hasSubscription() {
    return this.user.allSubscriptions.length > 0;
  }

  getAllSubscriptions() {
    return this.user.allSubscriptions;
  }

  getAllUserTrialSubscriptions() {
    const subsList = this.user.subscriptions.filter((subs) => subs.isTrial);
    return subsList;
  }

  getSubscriptions() {
    return this.user.subscriptions;
  }

  hasUserSubscription() {
    return this.user.subscriptionsInUse.length > 0;
  }

  getUserSubscription() {
    if (this.user.subscriptionsInUse.length > 0) {
      return this.user.subscriptionsInUse[0];
    }
    return null;
  }

  getCurrentSubscription(): SubscriptionModel | null {
    if (this.user.allSubscriptions && this.user.allSubscriptions.length > 0) {
      return this.user.allSubscriptions[0];
    }
    return null;
  }

  getCurrentSubscriptionItems() {
    const subs = this.getCurrentSubscription();
    if (subs) {
      return subs.items;
    }
    return [];
  }

  getSubscriptionEndDate() {
    let date = "-";
    if (this.user.allSubscriptions.length > 0) {
      date = this.user.allSubscriptions[0].endDate;
    }
    return date;
  }

  getSubscriptionPeriodEnd() {
    let date = "-";
    if (this.user.allSubscriptions.length > 0) {
      date = this.user.allSubscriptions[0].periodEndDate;
    }
    return date;
  }

  needRemainNotify() {
    const subs = this.getCurrentSubscription();
    if (subs && subs.subscriptionEndAt) {
      const endDate = subs.subscriptionEndAt;
      const diff = moment().diff(moment(endDate), "day");
      return diff > -7;
    }
    return false;
  }

  hasExpired() {
    return this.user.hasExpired;
  }

  isDeleted() {
    return this.user.deletedDate !== null;
  }

  hasOnlyMeteredSubscription() {
    return this.user.hasOnlyMeteredSubscription;
  }

  hasLicensedSubscription() {
    return this.user.hasLicensedSubscription;
  }

  usagePercent() {
    if (this.hasExpired()) {
      return 100;
    }
    const subs = this.getCurrentSubscription();
    if (subs) {
      if (subs.isPeriodic) {
        if (subs.subscriptionTotalDays && subs.subscriptionRemainDays) {
          const percent = (subs.subscriptionRemainDays / subs.subscriptionTotalDays) * 100;
          return Math.floor(100 - percent);
        } else {
          return 0;
        }
      } else {
        let quantity = 0;
        let usage = 0;
        subs.items.forEach((item) => {
          quantity += item.quantity;
          usage += item.usage;
        });
        return Math.floor((usage / quantity) * 100);
      }
    }
    return 100;
  }

  paymentInfo() {
    return "";
  }

  hasPayment() {
    return false;
  }

  subscriptionType() {
    let type = "Basic";
    if (this.user.subscriptionTier) {
      type = this.user.subscriptionTier.name;
    }
    return type;
  }

  isBasic() {
    return this.subscriptionType() === "Basic";
  }

  hasPermit(permit: TierName) {
    if (Config.TIER_CHECK) {
      if (permit === "Pro") {
        if (this.subscriptionType() === "Premium" || this.subscriptionType() === "Enterprise") {
          return true;
        }
        const targetTier = ProductPlan.PRO_ACCESS;
        return this.user.subscriptionTiers.filter((tier) => tier.name === targetTier.tier.name).length > 0;
      } else if (permit === "Premium") {
        if (this.subscriptionType() === "Enterprise") {
          return true;
        }
        const targetTier = ProductPlan.PREMIUM_ACCESS;
        return this.user.subscriptionTiers.filter((tier) => tier.name === targetTier.tier.name).length > 0;
      } else if (permit === "Enterprise") {
        const targetTier = ProductPlan.NON_EXTERNAL_AUDIT_PERMIT;
        return this.user.subscriptionTiers.filter((tier) => tier.name === targetTier.tier.name).length > 0;
      }
      return this.user.subscriptionTiers.find((tier) => tier.name === permit) !== undefined;
    }
    return true;
  }

  matchPermit(permit: TierName) {
    if (Config.TIER_CHECK) {
      if (permit === "Pro") {
        const targetTier = ProductPlan.PRO_ACCESS;
        return this.user.subscriptionTiers.filter((tier) => tier.name === targetTier.tier.name).length > 0;
      } else if (permit === "Premium") {
        const targetTier = ProductPlan.PREMIUM_ACCESS;
        return this.user.subscriptionTiers.filter((tier) => tier.name === targetTier.tier.name).length > 0;
      } else if (permit === "Enterprise") {
        const targetTier = ProductPlan.NON_EXTERNAL_AUDIT_PERMIT;
        return this.user.subscriptionTiers.filter((tier) => tier.name === targetTier.tier.name).length > 0;
      }
      return this.user.subscriptionTiers.find((tier) => tier.name === permit) !== undefined;
    }
    return true;
  }

  needRegisterPayment() {
    const date = moment().format("YYYY-MM-DD");
    if (localStorage.getItem(KEY_NEED_SHOW_PAYMENT_METHOD) === date) {
      return false;
    }
    return true;
  }

  laterRegisterPayment() {
    const date = moment().format("YYYY-MM-DD");
    localStorage.setItem(KEY_NEED_SHOW_PAYMENT_METHOD, date);
  }

  isNew() {
    return this.user.isNew;
  }

  usingFreeTier() {
    return this.user.usingFreeTier;
  }

  trialEndAt() {
    const subs = this.getUserSubscription();
    if (subs && subs.isTrial) {
      return subs.trialEndDate;
    }
    return null;
  }

  trialRemainDays() {
    const subs = this.getUserSubscription();
    if (subs && subs.isTrial) {
      return subs.subscriptionRemainDays;
    }
    return null;
  }

  cumulativeAmount() {
    return this.user.cumulativeAmount;
  }

  organizations() {
    return this.user.organizations;
  }

  userType() {
    return this.user.userType;
  }
}
