import styled from "styled-components";

export interface IProp {
  color?: string;
  fontWeight?: "bold" | "normal";
}

const HeaderTitle = styled.div`
  font-size: 16px;
  letter-spacing: -0.2px;
  color: ${(p: IProp) => p.color ?? "#096dd9"};
  font-weight: ${(p: IProp) => p.fontWeight ?? "bold"};
  text-align: center;
  line-height: 22px;
`;

export default HeaderTitle;
