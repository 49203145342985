import { Container, Button, Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import * as React from "react";
import H1 from "../../components/texts/H1";
import H3 from "../../components/texts/H3";
import InputText from "./InputText";
import styled from "styled-components";
import UserAccountService, { CustomError, ErrorCode } from "../../../services/UserService";
import useUser from "../../hook/useUser";
import { useHistory } from "react-router-dom";
import { RoutePath } from "../../components/RouteList";
import { Alert, Color } from "@material-ui/lab";
import { useIntl } from "react-intl";
import Locale from "../../../resources/Locale";
import DomainConfig from "../../../resources/DomainConfig";
import DomainSelectionPage from "../DomainSelectionPage";
import postVSTOEvent from "../../../utils/vsto_post_event";

const MarginH3 = styled(H3)`
  margin-top: 20px;
`;

const MarginButton = styled(Button)`
  margin-top: 20px;
`;

const StyledSnakebar = styled(Snackbar)`
  top: 60px;
`;

const StyleBackDrop = styled(Backdrop)`
  z-index: 99999;
  color: "#fff";
`;

function LoginPage() {
  const { user, mutate, loggedOut } = useUser();
  const intl = useIntl();
  const history = useHistory();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [domainList, setDomainList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [snackBarOption, setSnackBarOption] = React.useState({
    open: false,
    type: "success" as Color,
    message: "",
  });
  React.useEffect(() => {
    if (user && !loggedOut) {
      history.replace(RoutePath.DEFAULT);
    }
  }, [user, loggedOut]);

  const getDomainList = async () => {
    const domainList = await UserAccountService.getUseApplicationList(username);

    const parsedDomainList = domainList.filter((domain) => domain["name"] != "deepsearch-nice");

    if (parsedDomainList.length == 0) {
      throw {
        code: ErrorCode.NotFoundUserData,
        message: "message_login_error",
      } as CustomError;
    }
    if (parsedDomainList.length > 1) {
      console.log(parsedDomainList);
      setDomainList(parsedDomainList);
      return false;
    } else {
      console.log(parsedDomainList);
      DomainConfig.appId = parsedDomainList[0]["name"];
      DomainConfig.setAppInfoById(DomainConfig.appId);
      return true;
    }
  };

  const signInUser = async () => {
    try {
      let token = await UserAccountService.login(username, password);
      if (token) {
        try {
          postVSTOEvent({
            login: {
              login: "true",
              token,
              app_id: localStorage["ds_domain"],
            },
          });
        } catch (e) {}
        mutate();
        showSnackBar("success", "Log in sucessfully");
      } else {
        try {
          postVSTOEvent([{ login: "false" }]);
        } catch {
          console.log("post message 실패");
        }
        showSnackBar("error", "Could not login");
      }
    } catch (e) {
      throw e;
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedDomain = await getDomainList();
      if (selectedDomain) {
        await signInUser();
      }
    } catch (e) {
      if ("message" in e) {
        showSnackBar("error", Locale.formatMessage(intl, e.message));
      } else {
        showSnackBar("error", "Error");
      }
    }
    setIsLoading(false);
  };

  const showSnackBar = (type: Color, message: string) => {
    setSnackBarOption({ open: true, type: type, message: message });
  };

  const onSnackBarClose = (_event?: React.SyntheticEvent, _reason?: string) => {
    setSnackBarOption({ ...snackBarOption, open: false });
  };
  const pickedDomain = async (domain: string) => {
    DomainConfig.appId = domain;
    DomainConfig.setAppInfoById(domain);
    setIsLoading(true);
    try {
      await signInUser();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if ("message" in e) {
        showSnackBar("error", Locale.formatMessage(intl, e.message));
      } else {
        showSnackBar("error", "Error");
      }
    }
  };

  const resetLogin = () => {
    setDomainList([]);
    setUsername("");
    setPassword("");
  };

  const handleKeyPress = (event, key: string) => {
    if (event.key === "Enter") {
      if (key == "password") {
        onSubmit();
      }
    }
  };

  return domainList.length > 1 ? (
    <div>
      <DomainSelectionPage
        domainList={domainList}
        onDomainClick={pickedDomain}
        resetLogin={resetLogin}
      ></DomainSelectionPage>
      <StyleBackDrop open={isLoading}>
        <CircularProgress color="primary" />
      </StyleBackDrop>
      <StyledSnakebar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBarOption.open}
        autoHideDuration={2000}
        onClose={onSnackBarClose}
      >
        <Alert onClose={onSnackBarClose} severity={snackBarOption.type}>
          {snackBarOption.message}
        </Alert>
      </StyledSnakebar>
    </div>
  ) : (
    <Container maxWidth="xs">
      <H1 color="#222">{Locale.formatMessage(intl, "deepsearch_login")}</H1>
      <MarginH3 color="#222" fontWeight="normal">
        {Locale.formatMessage(intl, "login_description")}
      </MarginH3>
      <InputText
        id="email"
        headerText={Locale.formatMessage(intl, "email")}
        type="text"
        onChange={(e) => setUsername(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <InputText
        id="password"
        headerText={Locale.formatMessage(intl, "password")}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <MarginButton color="primary" variant="contained" disableElevation fullWidth onClick={onSubmit}>
        {Locale.formatMessage(intl, "login")}
      </MarginButton>
      {/* <MoreLoginOption /> */}
      <StyleBackDrop open={isLoading}>
        <CircularProgress color="primary" />
      </StyleBackDrop>
      <StyledSnakebar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackBarOption.open}
        autoHideDuration={2000}
        onClose={onSnackBarClose}
      >
        <Alert onClose={onSnackBarClose} severity={snackBarOption.type}>
          {snackBarOption.message}
        </Alert>
      </StyledSnakebar>
    </Container>
  );
}

export default LoginPage;
