import { Button, ClickAwayListener, Grid, IconButton, MenuItem, MenuList, Paper, Popover } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import * as React from "react";
import useUser from "../../hook/useUser";
import { RoutePath } from "../RouteList";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";
import styled from "styled-components";
import Locale from "../../../resources/Locale";
import { useIntl } from "react-intl";
import LanguagePicker from "./LanguagePicker";
import postVSTOEvent from "../../../utils/vsto_post_event";

const StyleIconButton = styled(IconButton)`
  color: #777e8c;
`;
const StyleMenuItem = styled(MenuItem)`
  font-size: 14px;
  &.Mui-selected {
    background: inherit;
    color: #1890ff;
  }
`;

const SmallScreenMenu = () => {
  const { user, mutate, loggedOut } = useUser();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();

  const handleMenuOpen = (_event: React.MouseEvent<HTMLElement>) => {
    // setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSignOut = async () => {
    try {
      await UserService.signOut(user.userName());
      mutate(null);
      postVSTOEvent({
        login: {
          login: false,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const AuthExistMenu = React.forwardRef(() => {
    return (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
      >
        <Paper style={{ minWidth: 150 }}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              {/* <MenuItem style={{ fontSize: 14 }} component={Link} to={RoutePath.LOGIN}>
                {Locale.formatMessage(intl, Locale.profile)}
              </MenuItem> */}
              <StyleMenuItem onClick={handleSignOut}>{Locale.formatMessage(intl, Locale["logout"])}</StyleMenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    );
  });

  const AuthNotExistMenu = React.forwardRef(() => {
    return (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
      >
        <Paper style={{ minWidth: 150 }}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              <MenuItem style={{ fontSize: 14 }} component={Link} to={RoutePath.LOGIN}>
                {Locale.formatMessage(intl, Locale["login"])}
              </MenuItem>
              {/* <MenuItem style={{ fontSize: 14 }}>
                <a href="https://deepsearch.com/?auth=signup" target="_blank">
                  {Locale.formatMessage(intl, Locale.signup)}
                </a>
              </MenuItem> */}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    );
  });
  return (
    <div style={{ display: "inline", whiteSpace: "nowrap", flexGrow: 1 }}>
      {user && !loggedOut ? (
        <Grid container spacing={2} justifyContent="flex-end">
          <LanguagePicker />
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            size="small"
            // onClick={handleMenuOpen}
          >
            {user.name()}
          </Button>
        </Grid>
      ) : (
        <Grid container spacing={2} justifyContent="flex-end">
          <LanguagePicker />
          <StyleIconButton
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            // onClick={handleMenuOpen}
          >
            <AccountCircle />
          </StyleIconButton>
        </Grid>
      )}

      {/* <Menu id="menu-appbar-right" keepMounted open={open} onClose={handleClose}> */}
      {user ? <AuthExistMenu></AuthExistMenu> : <AuthNotExistMenu />}
      {/* </Menu> */}
    </div>
  );
};

export default SmallScreenMenu;
