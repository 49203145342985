import useSWR from "swr";
import UserAccountService, { ErrorCode } from "../../services/UserService";

const REFRESH_PERIOD_MILLIS = 1000 * 60 * 5;
export default function useUser() {
  const { data, mutate, error, isValidating } = useSWR("api_user", UserAccountService.getCurrentUser, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    shouldRetryOnError: false,
    errorRetryCount: 1,
    refreshInterval: REFRESH_PERIOD_MILLIS,
  });

  const loading = !data && !error;
  const loggedOut = error && error.code in ErrorCode;

  return {
    loading,
    loggedOut,
    user: data,
    mutate,
    isValidating,
  };
}
