import * as React from "react";
import clsx from "clsx";
import { useTheme, Theme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Link, withRouter, useLocation } from "react-router-dom";
import RouteList, { RoutePath } from "../RouteList";
import { HomeIcon, ShareIcon, LogoIcon, SearchIcon, FxIcon, PackageIcon } from "../../../public/icons";
import { Backdrop, CircularProgress, Tooltip } from "@material-ui/core";
import SidebarRight from "./SidebarRight";
import useUser from "../../hook/useUser";
import { AppConfig } from "../../../app-config";
import Tracker from "../../../services/Tracker";
import { useIntl } from "react-intl";
import Locale from "../../../resources/Locale";
import { DEBUG_MODE } from "../../../resources/Config";
import LoginPage from "../../pages/LoginPage";
import DomainConfig from "../../../resources/DomainConfig";

import postVSTOEvent from "../../../utils/vsto_post_event";
import { GraphicEqOutlined } from "@material-ui/icons";
import useSidebarStyles from "./SidebarStyle";
import AlertDialogSlide from "./ValueSearchDialog";

interface sideBarItem {
  text: string;
  icon: any;
  path: string;
}

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 5,
  },
}))(Tooltip);

const SideBarMenu = () => {
  const classes = useSidebarStyles();
  const theme = useTheme();
  const location = useLocation();
  const intl = useIntl();

  const defaultSideBarsList = [
    {
      text: Locale.formatMessage(intl, "home_menu"),
      icon: HomeIcon,
      path: RoutePath.DEFAULT,
    },
    {
      text: Locale.formatMessage(intl, "search_query_menu"),
      icon: SearchIcon,
      path: RoutePath.QUERY,
    },
    {
      text: Locale.formatMessage(intl, "fs_menu"),
      icon: ShareIcon,
      path: RoutePath.DOWNLOAD,
    },
    {
      text: Locale.formatMessage(intl, "template_menu"),
      icon: PackageIcon,
      path: RoutePath.TEMPLATE,
    },
    {
      text: Locale.formatMessage(intl, "function_menu"),
      icon: FxIcon,
      path: RoutePath.FUNCTION,
    },
    {
      text: Locale.formatMessage(intl, "Screener"),
      icon: GraphicEqOutlined,
      path: RoutePath.SCREENER,
    },
  ];

  const [open, setOpen] = React.useState(false);

  const [items, setItems] = React.useState<sideBarItem[]>(defaultSideBarsList);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const clickAmplitudeEvent = (params: string, _e) => {
    Tracker.amplitudeEvent("menuclick", { page: params });
  };
  const { user, loading, loggedOut, mutate } = useUser();
  React.useEffect(() => {
    mutate();
  }, []);
  React.useEffect(() => {
    if ((user && !loggedOut) || localStorage.getItem("token") != null) {
      try {
        console.log({
          login: {
            login: "true",
            token: localStorage.getItem("token"),
            app_id: localStorage.getItem("ds_domain"),
          },
        });

        postVSTOEvent({
          login: {
            login: "true",
            token: localStorage.getItem("token"),
            app_id: localStorage.getItem("ds_domain"),
          },
        });
      } catch {}
    } else {
    }
  }, [user, loggedOut]);

  React.useEffect(() => {
    console.log(DomainConfig.appId);
  }, [DomainConfig.appId]);

  React.useEffect(() => {
    let sideBarItem = DEBUG_MODE ? items : items.filter((item) => item.path != RoutePath.FUNCTION);

    if (DomainConfig.appInfo.restricted?.sidebar) {
      sideBarItem = sideBarItem.filter((item) => !DomainConfig.appInfo.restricted?.sidebar?.includes(item.path));
      setItems(sideBarItem);
    } else {
      setItems(sideBarItem);
    }
    AppConfig.getAppConfig().then((app) => {
      Tracker.startup(app, user, {
        completed: (user: any) => {
          if (user !== undefined) {
            Tracker.amplitudeEvent("login");
            try {
              postVSTOEvent({
                amplitude: {
                  amplitude_key: Tracker.amplitude_key,
                  user_id: Tracker.userproperties.user_id,
                  plan: Tracker.userproperties.plan,
                  user_status: Tracker.userproperties.user_status,
                  orgs: Tracker.userproperties.orgs,
                  user_type: Tracker.userproperties.user_type,
                  app_id: Tracker.userproperties.app_id,
                },
              });
            } catch {}
          }
        },
      });
    });
  }, [user, DomainConfig.appInfo]);

  return (
    <div
      className={clsx(classes.appBar, {
        [classes.root]: user && !loggedOut,
      })}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={1}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbarContainer} disableGutters>
          {user && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          {user && (
            <div style={{ flexGrow: 1 }}>
              {DomainConfig.appId == "deepsearch" ||
              DomainConfig.appInfo.logoUrl == "" ||
              DomainConfig.appInfo.logoUrl == null ? (
                <LogoIcon className={classes.headerLogo} style={{ width: 30 }}></LogoIcon>
              ) : (
                <img
                  className={classes.headerLogo}
                  src={`${DomainConfig.appInfo.logoUrl}`}
                  style={{ maxWidth: 160 }}
                  alt="new"
                />
              )}
            </div>
          )}
          {!open && <SidebarRight />}
        </Toolbar>
      </AppBar>
      {user && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {items.map((item, index) => (
              <LightTooltip key={index} title={item.text} placement="right">
                <ListItem
                  button
                  component={Link}
                  to={item.path}
                  selected={item.path === location.pathname}
                  className={clsx(classes.listItem, {
                    [classes.listItemOpen]: open,
                  })}
                  disableGutters
                  onClick={(e) => clickAmplitudeEvent(item.path, e)}
                >
                  <ListItemIcon>
                    <item.icon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </LightTooltip>
            ))}
          </List>
        </Drawer>
      )}
      <main className={classes.content} style={{ paddingBottom: location.pathname == RoutePath.DEFAULT ? 0 : 120 }}>
        <div className={classes.toolbar} />
        {(user && !loggedOut) || location.pathname == RoutePath.DOWNLOAD_FILE ? (
          <div>
            <AlertDialogSlide />
            <div
              className={clsx({
                [classes.contentHeightHome]: location.pathname == RoutePath.DEFAULT,
              })}
            >
              <RouteList />
            </div>
          </div>
        ) : (
          <LoginPage />
        )}
      </main>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default withRouter(SideBarMenu);
