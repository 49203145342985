import loadable from "@loadable/component";
import * as React from "react";
import { Switch, Route } from "react-router-dom";
const TemplatePage = loadable(() => import("../pages/TemplatePage"));
const DomainSelectionPage = loadable(() => import("../pages/DomainSelectionPage"));
const DownloadPage = loadable(() => import("../pages/DownloadPage"));
const FunctionPage = loadable(() => import("../pages/FunctionPage"));
const HomePage = loadable(() => import("../pages/HomePage"));
const LoginPage = loadable(() => import("../pages/LoginPage"));
const ManualPage = loadable(() => import("../pages/ManualPage"));
const SearchPage = loadable(() => import("../pages/SearchPage"));
const FileDownload = loadable(() => import("../pages/FileDownload"));
const ScreenerPage = loadable(() => import("../pages/ScreenerPage"));
// import QueryPage from "../pages/QueryPage";

export const RoutePath = {
  DEFAULT: "/",
  QUERY: "/query",
  HELP: "/help",
  FS: "/fs",
  TEMPLATE: "/template",
  FUNCTION: "/function",
  DOWNLOAD: "/download",
  COMPANTY: "/company/",
  LOGIN: "/login",
  SCREENER: "/screener",
  DOMAIN_SELECTION: "/domain_selection",
  DOWNLOAD_FILE: "/function/download",
};

const RouteList = () => {
  return (
    <Switch>
      <Route exact path={RoutePath.DEFAULT} component={HomePage} />
      <Route exact path={RoutePath.QUERY} component={SearchPage} />
      <Route exact path={RoutePath.TEMPLATE} component={TemplatePage} />
      <Route exact path={RoutePath.FUNCTION} component={FunctionPage} />

      <Route exact path={RoutePath.FS} component={ManualPage} />
      <Route exact path={RoutePath.DOWNLOAD} component={DownloadPage} />
      <Route exact path={RoutePath.SCREENER} component={ScreenerPage} />

      {/* <Route exact path={`${RoutePath.COMPANTY}:id`} component={QueryPage} /> */}
      <Route exact path={RoutePath.LOGIN} component={LoginPage} />
      <Route exact path={RoutePath.DOMAIN_SELECTION} component={DomainSelectionPage} />
      <Route exact path={RoutePath.DOWNLOAD_FILE} component={FileDownload} />
    </Switch>
  );
};

export default RouteList;
