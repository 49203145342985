import { useMediaQuery } from "@material-ui/core";
import * as React from "react";
import SmallScreenMenu from "./SmallScreenMenu";
import BigScreenMenu from "./BigScreenMenu";

function SidebarRight() {
  const matches = useMediaQuery("(min-width:470px)");

  const RenderSideBar = () => {
    if (!matches) {
      return <SmallScreenMenu />;
    } else {
      return <BigScreenMenu />;
    }
  };
  return <RenderSideBar />;
}

export default SidebarRight;
