import { ApplicationInfo } from "../model/user/ApplicationInfoModel";
import LoginUserModel from "../model/user/LoginUserModel";
import ChannelTalkService, { createDefaultConfig } from "./ChannelTalkService.js";

import amplitude, { Config as AConfig } from "amplitude-js";

import Config from "../resources/Config";
import Locale from "../resources/Locale";

const APLITUDE_KEY = Config.DEBUG_MODE
  ? "fac51c683b06a71974021573c0b869df" // DEV
  : "1c6b82c5424fbde7ac7e4598b3d7bd01";

const ENABLED_LOG = Config.DEBUG_MODE;

interface IStartUpCallback {
  completed(user: any): void;
  error?(error: any): void;
}

class $Tracker {
  user?: LoginUserModel | null;
  channelService: ChannelTalkService;
  userproperties: any;
  amplitude_key: string;

  init() {
    this.channelService = new ChannelTalkService();
  }

  startup(app: ApplicationInfo, user?: LoginUserModel | null, callback?: IStartUpCallback) {
    this.user = user;
    this.userproperties = null;
    //ChannelTalk

    this.updateChannelTalkUser(user);
    this.channelService.boot(createDefaultConfig(app, user), callback);

    this.amplitude_key = APLITUDE_KEY;

    // Amplitude
    const amplitdueConfig: AConfig = {
      includeReferrer: false,
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      language: Locale.language,
    };
    amplitdueConfig["logAttributionCapturedEvent"] = true;

    amplitude.getInstance().init(APLITUDE_KEY, user ? user.uid() : undefined, amplitdueConfig, () => {
      this.updateUser(user);
    });
  }

  updateUser(user: LoginUserModel | undefined | null) {
    if (user) {
      let userProperties: any = {
        user_status: "nonmember",
        app_id: Config.App.appId,
      };
      if (user) {
        const orgs = user.organizations().map((e) => e.name) || [];
        userProperties = {
          // email: this.user.email(),
          // username: this.user.userName(),
          user_id: user.uid(),
          plan: user.subscriptionType(),
          user_status: "member",
          orgs: orgs,
          user_type: "user",
          app_id: Config.App.appId,
        };
      }
      this.userproperties = userProperties;
      amplitude.getInstance().setUserProperties(userProperties);

      if (Config.App.appId == "deepsearch-valuesearch") {
        this.closeChanelTalk();
      }
    }
  }

  amplitudeEvent(eventType: string, property?: any) {
    amplitude.getInstance().logEvent(eventType, property, (_client) => {});
    if (ENABLED_LOG) {
      console.log(`[TRACKER][AMPLITUDE EVENT] ${eventType}, property:`, property);
    }
  }

  dispose() {
    this.user = undefined;
    this.channelService.shutdown();
  }

  closeChanelTalk() {
    this.channelService.shutdown();
  }

  track(name: string, data: { [key: string]: string | number }) {
    this.channelService.track(name, data);
  }

  openChanelTalk() {
    this.channelService.open();
  }

  updateChannelTalkUser(user?: LoginUserModel) {
    if (user) {
      this.channelService.updateUser(user);
    } else {
      this.channelService.updateUser();
    }
  }
}

const Tracker = new $Tracker();
Tracker.init();
export default Tracker;
