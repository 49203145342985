import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
    ${reset};
    body {
        font-family: Noto Sans KR;
        letter-spacing: -0.2px;
        line-height: 1.4;
    }
    a {
        text-decoration:none;
        color:inherit;
        word-wrap:break-word;
    }

    * {
        box-sizing:border-box;
    }
`;

export default GlobalStyles;
