import * as React from "react";

import styled from "styled-components";

const StyleDiv = styled.div`
  margin-top: 20px;
`;

const StyleInput = styled.input`
  border: 1px solid #d1d8e5;
  width: 100%;
  border-radius: 4px;
  padding: 0 10px;
  height: 40px;
  font-size: 15px;
  &:focus {
    outline: none;
  }
`;

interface IProps {
  id?: string;
  headerText: string;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>, key?: string) => void;
}
const InputText = (props: IProps) => {
  return (
    <StyleDiv>
      <span>{props.headerText}</span>
      <br />
      <StyleInput
        type={props.type}
        autoCapitalize="none"
        autoCorrect="off"
        onChange={props.onChange}
        onKeyPress={(e) => {
          if (props.onKeyPress) {
            props.onKeyPress(e, props.id);
          }
        }}
      ></StyleInput>
    </StyleDiv>
  );
};

export default InputText;
