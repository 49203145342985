import { Button, Container, Divider } from "@material-ui/core";
import * as React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import Locale from "../../../resources/Locale";
import H1 from "../../components/texts/H1";
import H3 from "../../components/texts/H3";

const MarginH3 = styled(H3)`
  margin-top: 20px;
`;
const MarginButton = styled(Button)`
  margin-top: 20px;
`;
interface IProp {
  domainList: any[];
  resetLogin: () => void;
  onDomainClick: (domain: string) => void;
}
function DomainSelectionPage(props: IProp) {
  const intl = useIntl();
  const { domainList, onDomainClick, resetLogin } = props;

  return (
    <Container maxWidth="xs">
      <H1 color="#222">{Locale.formatMessage(intl, "domain_selection")}</H1>
      <MarginH3 color="#222" fontWeight="normal">
        {Locale.formatMessage(intl, "domain_selection_description", {
          br: <br />,
        })}
      </MarginH3>

      {domainList.map((data, index) => (
        <MarginButton
          key={index}
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={() => onDomainClick(data["name"])}
        >
          {data["display_name"]}
        </MarginButton>
      ))}
      <Divider variant="middle" style={{ margin: "35px 0 0 0" }} />
      <MarginButton color="primary" variant="contained" disableElevation fullWidth onClick={resetLogin}>
        {Locale.formatMessage(intl, "back_to_login")}
      </MarginButton>
    </Container>
  );
}

export default DomainSelectionPage;
