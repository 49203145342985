import styled from "styled-components";

export interface IProp {
  color?: string;
}
const HeaderTitle = styled.div`
  font-size: 24px;
  letter-spacing: -0.2px;
  color: ${(p: IProp) => p.color ?? "#096dd9"};
  font-weight: bold;
  text-align: center;
  line-height: 32px;
`;

export default HeaderTitle;
