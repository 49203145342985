import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#096DD9",
    },
    secondary: {
      main: "#1890FF",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 430,
      md: 660,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        // fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontFamily: `'Noto Sans KR', sans-serif`,
        textTransform: "none",
      },
    },
  },
});

export default theme;
