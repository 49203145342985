import { Button, Popover, Paper, ClickAwayListener, MenuList, MenuItem, Grid } from "@material-ui/core";
import { Color } from "@material-ui/lab";
import * as React from "react";
import styled from "styled-components";
import Locale from "../../../resources/Locale";
import CustomSnakebar from "../CustomSnakebar";
import { useIntl } from "react-intl";

const StyleMenuItem = styled(MenuItem)`
  font-size: 14px;
  &.Mui-selected {
    background: inherit;
    color: #1890ff;
  }
`;

function LanguagePicker() {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [isKo, setIsKo] = React.useState(true);
  const [snackBarOption, setSnackBarOption] = React.useState({
    open: false,
    type: "info" as Color,
    message: Locale.formatMessage(intl, "switching_language"),
  });

  React.useEffect(() => {
    setIsKo(Locale.korean);
  }, [isKo]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>, languageCode?: string) => {
    if (languageCode) {
      if (Locale.language != languageCode) {
        Locale.language = languageCode;
        setSnackBarOption({ ...snackBarOption, open: true });
      }
    }
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <Grid item>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="small"
      >
        {Locale.korean ? "한국어" : "English"}
      </Button>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
      >
        <Paper style={{ minWidth: 150 }}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              <StyleMenuItem selected={Locale.languageCode == "en"} onClick={(e) => handleClose(e, "en_US")}>
                English
              </StyleMenuItem>
              <StyleMenuItem selected={Locale.languageCode == "ko"} onClick={(e) => handleClose(e, "ko_KR")}>
                한국어
              </StyleMenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
      <CustomSnakebar
        open={snackBarOption.open}
        type={snackBarOption.type}
        message={snackBarOption.message}
        // onClose={onSnackBarClose}
      />
    </Grid>
  );
}

export default LanguagePicker;
