import Config from "../resources/Config";
import Locale from "../resources/Locale";

export default class ChannelTalkService {
  constructor(isRunning, user) {
    this.user = user;
    this.isRunning = false;
    this.loadScript();
  }

  loadScript() {
    const w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})("ChannelIO script included twice.");
    }
    const ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
      s.charset = "UTF-8";
      const x = document.getElementsByTagName("script")[0];
      if (x && x.parentNode) {
        x.parentNode.insertBefore(s, x);
      }
    }
    if (document.readyState === "complete") {
      l();
    } else if (window.attachEvent) {
      window.attachEvent("onload", l);
    } else {
      window.addEventListener("DOMContentLoaded", l, false);
      window.addEventListener("load", l, false);
    }
  }

  boot(settings, callback) {
    window.ChannelIO("boot", settings, (error, users) => {
      if (error) {
        console.error("CH Boot error = ", error);
        if (callback && callback.error) {
          callback.error(error);
        }
      } else {
        // if (Config.DEBUG_MODE) console.log("CH Boot completed", user);
        if (users) {
          this.user = users;
        }
        if (callback && callback.completed) {
          callback.completed(users);
        }
      }
    });
    this.isRunning = true;
  }

  shutdown() {
    if (this.isRunning) {
      window.ChannelIO("shutdown");
      this.isRunning = false;
    }
  }

  open() {
    window.ChannelIO("show");
  }

  openChat(chatId) {
    window.ChannelIO("openChat", chatId);
  }

  show() {
    window.ChannelIO("showChannelButton");
  }

  hide() {
    window.ChannelIO("hideChannelButton");
  }

  track(eventName, data) {
    if (this.isRunning) {
      window.ChannelIO("track", eventName, data);
    }
  }

  userId() {
    if (this.user) {
      return this.user.id;
    }
    return null;
  }

  updateUser(user) {
    let updatedObject = {
      language: Locale.languageCode,
      unsubscribed: true,
    };
    if (user) {
      updatedObject["profile"] = createUserProfileObject(user);
    } else {
      updatedObject["profile"] = null;
    }
    window.ChannelIO("updateUser", updatedObject, function onUpdateUser(error, user) {
      if (error) {
        console.error(error);
      } else {
        console.log("updateUser success");
      }
    });

    // if (!this.hasUpdatedUser) {
    //   window.ChannelIO("updateUser", object, (error, user) => {
    //     if (error) {
    //       console.warn(error);
    //     } else {
    //       this.hasUpdatedUser = true;
    //     }
    //   });
    // } else {
    // }
  }
}
//www.deepsearch.com/?=facebook&=post&=market_research&fbclid=IwAR1SWA6BREFn3aIyH43PI2E2yG5gqtlK8ZlfwkqnlLk9Hq_Osz-yKkYzkIg
const checkUtm = (profile) => {
  /**
   * Linkedin
   * params : li_fat_id
   */
  const params = new URLSearchParams(document.location.search);
  const utmSource = params.get("utm_source");
  const utmMedium = params.get("utm_medium");
  const utmTerm = params.get("utm_term");
  const utmCampaign = params.get("utm_campaign");
  const utmContent = params.get("utm_content");
  return {
    ...profile,
    utmSource: utmSource,
    utmMedium: utmMedium,
    utmTerm: utmTerm,
    utmCampaign: utmCampaign,
    utmContent: utmContent,
  };
};

const linkedInCampaign = (referrer, profile) => {
  /**
   * Linkedin
   * params : li_fat_id
   */
  if (referrer.includes("linkedin.com")) {
    const params = new URLSearchParams(document.location.search);
    const id = params.get("li_fat_id");
    if (id) {
      return {
        ...profile,
        linkedInFatId: id,
      };
    }
  }
  return profile;
};

const facebookCampaingn = (referrer, profile) => {
  /**
   * Facebook
   * params : fbclid
   */
  if (referrer.includes("facebook.com")) {
    const params = new URLSearchParams(document.location.search);
    const id = params.get("fbclid");
    if (id) {
      return {
        ...profile,
        fbclid: id,
      };
    }
  }
  return profile;
};

/**
 *
 * @param {ApplicationInfo} app
 * @param {LoginUserModel} user
 */
export const createDefaultConfig = (app, user) => {
  const config = {
    pluginKey: app.getChannelTalkKey(),
    hideDefaultLauncher: false,
    enabledTrackDefaultEvent: false,
    enabledUtmSourceTrack: false,
    openChatDirectlyAsPossible: true,
    mobileOpenUI: "newTab",
    zIndex: 3,
    language: Locale.languageCode,
  };

  const referrer = document.referrer;
  const host = document.location.host;
  let profile = {};
  if (!referrer.includes(host)) {
    profile = {
      referrer: referrer,
    };
  }
  profile = checkUtm(profile);
  profile = linkedInCampaign(referrer, profile);
  profile = facebookCampaingn(referrer, profile);

  if (user) {
    config["memberId"] = user.uid();
    profile = createUserProfileObject(user);
  }
  config["profile"] = profile;
  return config;
};

const createUserProfileObject = (user) => {
  let profile = {};
  if (user) {
    profile = {
      ...profile,
      name: user.name(),
      email: user.email(),
      subscriptionType: user.subscriptionType(),
      cumulativeAmount: user.cumulativeAmount(),
      organization: user.organizationName(),
      team: user.teamName(),
      position: user.position(),
      appId: user.appName(),
      appName: Config.App.name,
      // ...user.menuCounts(),
      // ...user.deniedMenuCounts(),
    };
    const subs = user.getUserSubscription();
    if (subs && !subs.autoCancel) {
      profile["nextPayDate"] = subs.periodEndDate;
    } else {
      profile["nextPayDate"] = null;
    }
  }
  return profile;
};

// export default ChannelTalkService;
