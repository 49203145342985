import { Button, Grid } from "@material-ui/core";

import * as React from "react";
import useUser from "../../hook/useUser";
import { RoutePath } from "../RouteList";
import { Link } from "react-router-dom";
import UserService from "../../../services/UserService";
import Locale from "../../../resources/Locale";
import { useIntl } from "react-intl";
import LanguagePicker from "./LanguagePicker";
import postVSTOEvent from "../../../utils/vsto_post_event";

const BigScreenMenu = () => {
  const { user, mutate, loggedOut } = useUser();
  const intl = useIntl();

  const handleSignOut = async () => {
    try {
      await UserService.signOut(user.userName());
      mutate(null);
      postVSTOEvent({
        login: {
          login: false,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div style={{ display: "inline", whiteSpace: "nowrap", flexGrow: 1 }}>
      {user && !loggedOut ? (
        <Grid container spacing={2} justifyContent="flex-end">
          <LanguagePicker />
          <Grid item>
            <Button size="small">{user.name()}</Button>
          </Grid>
          <Grid item>
            <Button size="small" onClick={handleSignOut}>
              {Locale.formatMessage(intl, Locale["logout"])}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} justifyContent="flex-end">
          <LanguagePicker />
          <Grid item>
            <Button size="small" component={Link} to={RoutePath.LOGIN}>
              {Locale.formatMessage(intl, Locale["login"])}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default BigScreenMenu;
