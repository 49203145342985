import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { initializeIcons } from "@fluentui/react";
import Locale from "../resources/Locale";
import { IntlProvider } from "react-intl";
/* global AppContainer, document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "DataCloud";

const render = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <IntlProvider locale={Locale.languageCode} defaultLocale={Locale.languageCode} messages={Locale._stringRes}>
        <Component title={title} isOfficeInitialized={isOfficeInitialized} />
      </IntlProvider>
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
// Office.initialize = () => {
//   isOfficeInitialized = true;
//   render(App);
// };
render(App);

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
