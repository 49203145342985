import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { has, set } from "core-js/core/dict";
import DomainConfig from "../../../resources/DomainConfig";

const useStyles = makeStyles(
  createStyles({
    description: {
      margin: "3px 6px",
      fontSize: 12,
      overflow: "hidden",
      wordWrap: "break-word",
    },
    title: {
      margin: "3px 6px",
      fontSize: 12,
      overflow: "hidden",
      wordWrap: "break-word",
    },
  })
);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [hasOpenDialog, setHasOpenDialog] = React.useState(false);

  React.useEffect(() => {
    if (DomainConfig.appId === "deepsearch-valuesearch") {
      setOpen(true);
    }
  }, [DomainConfig.appId]);

  const handleClose = () => {
    setOpen(false);
    setHasOpenDialog(true);
  };

  return (
    <div>
      <Dialog
        open={!hasOpenDialog && open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.title}>
          ValueSearch 서비스 리뉴얼 안내
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className={classes.description}>
            안녕하세요, NICE평가정보입니다. <br /> 현재 사용하고 계신 ValueSearch는 2023년 9월 리뉴얼 되어, 24년 1월
            말까지 사용 가능함을 안내드립니다.
            <br />
            리뉴얼 버전은 구 서비스인 KISVALUE의 데이터를 대부분 제공할 수 있도록 변경하였으며, Web(
            <Link target="_blank" rel="noopener" href="https://www.valuesearch.co.kr">
              www.valuesearch.co.kr
            </Link>
            )과 Excel add-in 모두 사용가능하여 사용자 O/S 환경, 망분리 이슈에 관계없이 서비스 이용을 하실 수 있습니다.
            리뉴얼 버전에 대한 자세한 사항은
            <Link target="_blank" rel="noopener" href="https://www.nicevse.com">
              www.nicevse.com
            </Link>
            에서 확인 가능합니다.
            <br />
            <br />
            감사합니다.
            <br />
            ※KISVALUE 서비스는 2023년 12월 28일 목요일 종료 예정입니다.
            <br />
            [관련 문의]
            <br />
            (1) 일반기업 : 02-3771-1600
            <br />
            (2) 공공/회계법인/연구소 : 02-3771-1599
            <br />
            (3) 대학 : 02-3771-1531
            <br />
            (4) 금융업 : 02-3771-1700
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
